import Product1 from "../../images/product1.jpeg";
import ComingSoon from "../../images/comingSoon.jpg";

const reviewData = [
  {
    id: 1,
    smallDesc:
      "“Saarthi” aims to be a pioneer and revolutionize the way hiring occurs in India. Our long term vision is to connect every person to the correct opportunity right from being a student to a job seeker.",
    image: Product1,
    productName: "Saarthi",
  },
  {
    id: 2,
    smallDesc: "Aam Admi ka Business Network.",
    image: ComingSoon,
    productName: "Kaamkaaz",
  },
];

export default reviewData;
